export default {
	internal_name: {
		value: "internal_name",
		display: true,
		component: "v-text-field",
		label: "Internal Name",
	},
	type: {
		value: "type",
		display: true,
		items: ["screener", "consent-form", "survey", "system-form"],
		component: "v-select",
		label: "Type",
	},
	title: {
		value: "title",
		display: true,
		component: "v-text-field",
		label: "Title",
	},
	subtitle: {
		value: "subtitle",
		display: true,
		component: "v-text-field",
		label: "Subtitle",
	},
	header_image: {
		value: "header_image",
		display: true,
		component: "mw-upload",
		label: "Header Image",
	},
	logo: {
		value: "logo",
		display: true,
		component: "mw-upload",
		label: "Client Logo",
	},
	introduction: {
		value: "introduction",
		component: "v-textarea",
		display: true,
		label: "Introduction",
		options: {
			hint: "You can also reference a content id here",
		},
	},
	show_sidebar: {
		value: "show_sidebar",
		component: "v-checkbox",
		display: true,
		default: true,
		label: "Show sidebar with about us information",
	},
	show_completion_rate_bar: {
		value: "show_completion_rate_bar",
		component: "v-checkbox",
		display: true,
		default: false,
		label: "Show completion rate bar",
	},
	identify_participant: {
		value: "identify_participant",
		component: "v-checkbox",
		display: false,
		default: true,
		label: "Ask participants for contact details and invite registered users to log in",
	},
	questions: {
		value: "questions",
		display: true,
		default: [],
	},
	thankyou: {
		value: "thankyou",
		component: "v-textarea",
		display: false,
		label: "Thank you message",
		options: {
			hint: "You can also reference a content id here",
		},
	},
	url: {
		value: "url",
		component: "v-text-field",
		display: false,
		label: "URL-part to display survey on",
        options: {
            hint: "This final url will be mw-participants/surveys/%this_field%"
        }
	},
	redirect_url: {
		value: "redirect_url",
		component: "v-text-field",
		display: false,
		label: "URL to redirect the user to",
        options: {
            hint: "The entire URL"
        }
	},
    redirect_to_scheduling: {
        value: "redirect_to_scheduling",
        display: false, 
		label: "Redirect to scheduling",
        component: "mw-select", 
		options: {
			hint: "Select the project",
			storeModule: "projects",
			itemText: "internal_name"
		}
    },
	tracker: {
		value: "tracker",
		display: true,
		component: "v-text-field",
		label: "Tracker",
	},
};

