<template>
	<v-sheet>
		<list-filter v-model="showTypes" :options="allTypes" title="Select Survey Type"></list-filter>
		<mw-list storeModule="surveys" :columns="headers" :filtered="includeIds"> </mw-list>
	</v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
import ListFilter from "@c/lists/ListFilter";
import Survey from "@/models/Survey";
export default {
	name: "Surveys",
	data: () => {
		return {
			showTypes: [],
		};
	},
	computed: {
		headers() {
			return [
				{ value: "internal_name", text: "Internal Name", default: true },
				{ value: "type", text: "Type"},
				{ value: "url", text: "Url"},
			];
		},
		allTypes() {
			return Survey.type.items;
		},
		surveys(){
			return this.$store.state.surveys.data
		},
		includeIds(){
			const self = this;
			let ids = Object.keys(self.surveys)  
			if( self.showTypes.length ){
				ids = ids.filter( id => self.showTypes.includes(self.surveys[id].type) )
			}
			return ids;
		}
	},
	components: {
		MwList,
		ListFilter
	},
};
//
</script>
"
